import { UserSession } from "@marta/common-utils";

export type AuthState = Partial<UserSession> & {
  loggedIn: boolean;
  expiredSession: boolean;
  biometricsToken: string;
  promptBiometrics: boolean;
};

export const initialAppState: AuthState = {
  loggedIn: false,
  expiredSession: false,
  biometricsToken: "",
  promptBiometrics: false,
};
