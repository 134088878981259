/**
 * Create the store with dynamic reducers
 */
import AsyncStorage from "@react-native-community/async-storage";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

export function configureAppStore(
  storeKey: string,
  reducers: ReturnType<typeof combineReducers>,
  blacklist: string[] = [],
) {
  const persistConfig = {
    key: storeKey,
    storage: AsyncStorage,
    blacklist: ["loggedIn", ...blacklist],
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => [
      // logger,
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
    devTools: process.env.NODE_ENV !== "production",
  });

  const persistor = persistStore(store);

  return { store, persistor };
}
