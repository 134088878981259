import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";

import { UserSession } from "@marta/common-utils";
import {
  getActionDispatcherWithoutPayload,
  getActionDispatcherWithPayload,
} from "@marta/utils";

import { AuthState, initialAppState } from "./auth-types";

export const setAuthInfo = createAction<UserSession>("setAuthInfo");
export const useAuthSetter = getActionDispatcherWithPayload(setAuthInfo);

export const resetAuthInfo = createAction("resetAuthInfo");
export const useAuthResetter = getActionDispatcherWithoutPayload(resetAuthInfo);

type TokenUpdaterPayload = Pick<UserSession, "accessToken" | "refreshToken">;
export const updateTokens = createAction<TokenUpdaterPayload>("updateTokens");
export const useTokenUpdater = getActionDispatcherWithPayload(updateTokens);

export const setExpiredSession = createAction<boolean>("setExpiredSession");
export const useExpiredSessionSetter =
  getActionDispatcherWithPayload(setExpiredSession);

export const setBiometricsToken = createAction<string>("setBiometricsToken");
export const useBiometricsTokenSetter =
  getActionDispatcherWithPayload(setBiometricsToken);

export const setPromptBiometrics = createAction<boolean>("setPromptBiometrics");
export const useBiometricsPrompter =
  getActionDispatcherWithPayload(setPromptBiometrics);

export const createSlice = <State>(options: {
  initialState: State;
  reducers?: (builder: ActionReducerMapBuilder<State & AuthState>) => void;
}) => {
  const reducer = createReducer(
    Object.assign(initialAppState, options.initialState),
    (builder) => {
      builder
        .addCase(setAuthInfo, (state, action) => {
          state.loggedIn = true;
          // session token id
          state.id = action.payload.id;
          state.userId = action.payload.userId;
          state.familyId = action.payload.familyId;
          state.clientId = action.payload.clientId;
          state.caregiverId = action.payload.caregiverId;
          state.role = action.payload.role;
          state.accessToken = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        })
        .addCase(resetAuthInfo, (state) => {
          state.loggedIn = false;
          state.id = undefined;
          state.userId = undefined;
          state.familyId = undefined;
          state.clientId = undefined;
          state.caregiverId = undefined;
          state.role = undefined;
          state.accessToken = undefined;
          state.refreshToken = undefined;
        })
        .addCase(updateTokens, (state, action) => {
          state.accessToken = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        })
        .addCase(setExpiredSession, (state, action) => {
          state.expiredSession = action.payload;
        })
        .addCase(setBiometricsToken, (state, action) => {
          state.biometricsToken = action.payload;
        })
        .addCase(setPromptBiometrics, (state, action) => {
          state.promptBiometrics = action.payload;
        });

      if (options?.reducers) options.reducers(builder);
    },
  );

  return {
    reducer,
    getInitialState: reducer.getInitialState,
    actions: {
      setAuthInfo,
      resetAuthInfo,
      updateTokens,
      setBiometricsToken,
    },
  };
};
